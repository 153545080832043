@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "VitoLight";
  src: url("../public/Vito-ExtendedLight.otf");
}

@font-face {
  font-family: "VitoReg";
  src: url("../public/Vito-ExtendedRegular.otf");
}

@font-face {
  font-family: "VitoMed";
  src: url("../public/Vito-ExtendedMedium.otf");
}

@font-face {
  font-family: "VitoBold";
  src: url("../public/Vito-ExtendedBold.otf");
}

/* =================================== SCROLL BAR STYLING =================================== */
/* Scrollbar modification */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #161618;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}

/* =================================== TOAST BODY STYLING =================================== */
.toastBodySuccess {
  @apply font-IBMPlex font-semibold text-green-600;
}
.toastBodyError {
  @apply font-IBMPlex font-semibold text-[#FF0033];
}

/* =============================== TEXT GLITCH =============================== */
body {
  color: #ccc;
  background: #111;
  font-family: sans-serif;
}

h1,
h2 {
  margin: 0;
}

a {
  color: #ccc;
}

section {
  padding: 20px;
}

.hero {
  /* font-size: clamp(40px, 10vw, 100px); */
  line-height: 1;
  display: inline-block;
  color: #fff;
  z-index: 2;

  /* edited letter spacing here */
  letter-spacing: 0px;

  /* Bright things in dark environments usually cast that light, giving off a glow */
  filter: drop-shadow(0 1px 3px);
}

.hero2 {
  /* font-size: clamp(40px, 10vw, 100px); */
  line-height: 1;
  display: inline-block;
  color: #ff0033;
  z-index: 2;
  letter-spacing: 0px;

  /* Bright things in dark environments usually cast that light, giving off a glow */
  filter: drop-shadow(0 1px 3px);
}

.demo {
  height: 100px;
  background: #fff;
}

.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 110%;
  z-index: -1;
}

.layers::before {
  top: 10px;
  left: 15px;
  color: #e0287d;
}

.layers::after {
  top: 5px;
  left: -10px;
  color: #1bc7fb;
}

.single-path {
  clip-path: polygon(
    0% 12%,
    53% 12%,
    53% 26%,
    25% 26%,
    25% 86%,
    31% 86%,
    31% 0%,
    53% 0%,
    53% 84%,
    92% 84%,
    92% 82%,
    70% 82%,
    70% 29%,
    78% 29%,
    78% 65%,
    69% 65%,
    69% 66%,
    77% 66%,
    77% 45%,
    85% 45%,
    85% 26%,
    97% 26%,
    97% 28%,
    84% 28%,
    84% 34%,
    54% 34%,
    54% 89%,
    30% 89%,
    30% 58%,
    83% 58%,
    83% 5%,
    68% 5%,
    68% 36%,
    62% 36%,
    62% 1%,
    12% 1%,
    12% 34%,
    60% 34%,
    60% 57%,
    98% 57%,
    98% 83%,
    1% 83%,
    1% 53%,
    91% 53%,
    91% 84%,
    8% 84%,
    8% 83%,
    4% 83%
  );
}

.paths {
  animation: paths 5s step-end infinite;
}

@keyframes paths {
  0% {
    clip-path: polygon(
      0% 43%,
      83% 43%,
      83% 22%,
      23% 22%,
      23% 24%,
      91% 24%,
      91% 26%,
      18% 26%,
      18% 83%,
      29% 83%,
      29% 17%,
      41% 17%,
      41% 39%,
      18% 39%,
      18% 82%,
      54% 82%,
      54% 88%,
      19% 88%,
      19% 4%,
      39% 4%,
      39% 14%,
      76% 14%,
      76% 52%,
      23% 52%,
      23% 35%,
      19% 35%,
      19% 8%,
      36% 8%,
      36% 31%,
      73% 31%,
      73% 16%,
      1% 16%,
      1% 56%,
      50% 56%,
      50% 8%
    );
  }

  5% {
    clip-path: polygon(
      0% 29%,
      44% 29%,
      44% 83%,
      94% 83%,
      94% 56%,
      11% 56%,
      11% 64%,
      94% 64%,
      94% 70%,
      88% 70%,
      88% 32%,
      18% 32%,
      18% 96%,
      10% 96%,
      10% 62%,
      9% 62%,
      9% 84%,
      68% 84%,
      68% 50%,
      52% 50%,
      52% 55%,
      35% 55%,
      35% 87%,
      25% 87%,
      25% 39%,
      15% 39%,
      15% 88%,
      52% 88%
    );
  }

  30% {
    clip-path: polygon(
      0% 53%,
      93% 53%,
      93% 62%,
      68% 62%,
      68% 37%,
      97% 37%,
      97% 89%,
      13% 89%,
      13% 45%,
      51% 45%,
      51% 88%,
      17% 88%,
      17% 54%,
      81% 54%,
      81% 75%,
      79% 75%,
      79% 76%,
      38% 76%,
      38% 28%,
      61% 28%,
      61% 12%,
      55% 12%,
      55% 62%,
      68% 62%,
      68% 51%,
      0% 51%,
      0% 92%,
      63% 92%,
      63% 4%,
      65% 4%
    );
  }

  45% {
    clip-path: polygon(
      0% 33%,
      2% 33%,
      2% 69%,
      58% 69%,
      58% 94%,
      55% 94%,
      55% 25%,
      33% 25%,
      33% 85%,
      16% 85%,
      16% 19%,
      5% 19%,
      5% 20%,
      79% 20%,
      79% 96%,
      93% 96%,
      93% 50%,
      5% 50%,
      5% 74%,
      55% 74%,
      55% 57%,
      96% 57%,
      96% 59%,
      87% 59%,
      87% 65%,
      82% 65%,
      82% 39%,
      63% 39%,
      63% 92%,
      4% 92%,
      4% 36%,
      24% 36%,
      24% 70%,
      1% 70%,
      1% 43%,
      15% 43%,
      15% 28%,
      23% 28%,
      23% 71%,
      90% 71%,
      90% 86%,
      97% 86%,
      97% 1%,
      60% 1%,
      60% 67%,
      71% 67%,
      71% 91%,
      17% 91%,
      17% 14%,
      39% 14%,
      39% 30%,
      58% 30%,
      58% 11%,
      52% 11%,
      52% 83%,
      68% 83%
    );
  }

  76% {
    clip-path: polygon(
      0% 26%,
      15% 26%,
      15% 73%,
      72% 73%,
      72% 70%,
      77% 70%,
      77% 75%,
      8% 75%,
      8% 42%,
      4% 42%,
      4% 61%,
      17% 61%,
      17% 12%,
      26% 12%,
      26% 63%,
      73% 63%,
      73% 43%,
      90% 43%,
      90% 67%,
      50% 67%,
      50% 41%,
      42% 41%,
      42% 46%,
      50% 46%,
      50% 84%,
      96% 84%,
      96% 78%,
      49% 78%,
      49% 25%,
      63% 25%,
      63% 14%
    );
  }

  90% {
    clip-path: polygon(
      0% 41%,
      13% 41%,
      13% 6%,
      87% 6%,
      87% 93%,
      10% 93%,
      10% 13%,
      89% 13%,
      89% 6%,
      3% 6%,
      3% 8%,
      16% 8%,
      16% 79%,
      0% 79%,
      0% 99%,
      92% 99%,
      92% 90%,
      5% 90%,
      5% 60%,
      0% 60%,
      0% 48%,
      89% 48%,
      89% 13%,
      80% 13%,
      80% 43%,
      95% 43%,
      95% 19%,
      80% 19%,
      80% 85%,
      38% 85%,
      38% 62%
    );
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    clip-path: none;
  }
}

.movement {
  /* Normally this position would be absolute & on the layers, set to relative here so we can see it on the div */
  position: relative;
  animation: movement 8s step-end infinite;
}

@keyframes movement {
  0% {
    top: 0px;
    left: -20px;
  }

  15% {
    top: 10px;
    left: 10px;
  }

  60% {
    top: 5px;
    left: -10px;
  }

  75% {
    top: -5px;
    left: 20px;
  }

  100% {
    top: 10px;
    left: 5px;
  }
}

.opacity {
  animation: opacity 5s step-end infinite;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}

.font {
  animation: font 7s step-end infinite;
}

@keyframes font {
  0% {
    font-weight: 100;
    color: #e0287d;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #e0287d;
    filter: blur(6px);
  }
}

.glitch span {
  animation: paths 5s step-end infinite;
}

.glitch::before {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 7s step-end infinite, movement 8s step-end infinite;
}

.hero-container {
  /* position: relative; */
  /* padding: 200px 0; */
  text-align: center;
}
/* 
.environment {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  filter: blur(5px);
  background: url(https://images.unsplash.com/photo-1602136773736-34d445b989cb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80)
    center no-repeat;
  background-size: cover;
} */

/* =============================== BTN LINES =============================== */
.btn-fi {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  background: rgba(186, 0, 37, 0.1);
  border: 1px solid rgba(186, 0, 37, 0.3);
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  letter-spacing: 1.8px;
  margin-top: 25px;
  outline: none;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 200px;
}
.btn-fi-lg {
  padding-left: 25px;
  padding-right: 25px;
  width: auto;
}
.btn-fi:before {
  left: 10%;
  top: 0;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}
.btn-fi:after,
.btn-fi:before {
  background: #ff0033;
  content: "";
  height: 1px;
  position: absolute;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 60px;
}
.btn-fi:after {
  bottom: 0;
  right: 10%;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}
.btn-fi:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(186, 0, 37, 0.3);
  box-shadow: 1px 1px 8px rgba(186, 0, 37, 0.3);
  color: #ff0033;
  text-shadow: 0 0 8px rgba(186, 0, 37, 0.4);
}
.btn-fi:hover.btn-fi:before {
  left: 0;
  width: 20px;
}
.btn-fi:hover.btn-fi:after {
  right: 0;
  width: 20px;
}
.btn-fi:hover .btn-fi-line:before {
  bottom: 0;
}
.btn-fi:hover .btn-fi-line:after {
  top: 0;
}
.btn-fi-line:before {
  bottom: 30%;
  right: 0;
  -webkit-transform: translateX(1px);
  transform: translateX(1px);
}
.btn-fi-line:after,
.btn-fi-line:before {
  background: #ff0033;
  content: "";
  height: 20px;
  position: absolute;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: 1px;
}
.btn-fi-line:after {
  left: 0;
  top: 30%;
  -webkit-transform: translateX(-1px);
  transform: translateX(-1px);
}
